import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Benefits.module.sass";
import HeroCard from "../Features/HeroCard";

const heroItems = [
  {
    title: "Spark My Sport",
    header: "Unlock your young athlete's potential",
    content:"Our innovative platform showcases young athletes to college scouts and pro coaches, professionally displaying their achievements, skills, and stats to help them stand out in sports recruitment.",
    reverse: false,
    videoUrl: "https://skilltech-v2-public.s3.us-west-1.amazonaws.com/website/Promo.mp4",
    thumbnailUrl: "https://skillteck-v2.s3.us-west-1.amazonaws.com/videos/1/library/intro.jpg",
  },
];

const Benefits = ({ className }) => {
  return (
    <div className={cn(className, styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={cn("stage", styles.stage)}>
          Our Platform
        </div>
        <h2 className={cn(styles.title)}>
          Benefits of Video Training
        </h2>
        <div className={styles.text}>
          Video adds structure to the game, providing coaches with the time and flexibility to 
          develop winning strategies while making the learning experience enjoyable for athletes. 
          It also creates new opportunities to monetize coaching and provide training services, 
          both on and off the field.
        </div>
        <Link className={cn("button", styles.contactbutton)} to="/contact">
          Contact Us
        </Link>
        <div className={styles.videoWrapper}>
          <div className={styles.videoContainer}>
            <HeroCard item={heroItems[0]} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Benefits;
