import React from "react"
import styles from "./Paycard.module.sass"
import Image from "../../components/Image";
import cn from "classnames";

const handleLocalRedirect = (page) => {
  window.location.href = `/${page}`
}

const handleRedirect = (link) => {
  window.location.href = link
}

const handleCardClick = (url) => {
    handleRedirect(url);

}

// info, 0 or 1
// active, 0-2  
const Paycard = ({ price, border = false }) => {
  return(
    <>
      <div className={cn(styles.paycard, { [styles.withBorder]: border } )}>
        <div className={styles.cardheader}>
          <div className={cn(styles.cardtag, styles.cardtag_active )}> 
            <p className={cn(styles.tag, styles.tag_inactive)}>
              {price['planName']}
            </p>
          </div>
          <div className={styles.cardpricename}>
            <div className={styles.row16}>
              <p className={styles.title}> {price['name']} </p>
            </div>  
              <div className={styles.price}>
                <p className={styles.cost}>
                  {price['price']}
                </p>
                <p className={styles.time}>
                {price['info1']}
                </p>
                {price['info2'].length >0 && 
                  <p className={styles.bullet}>
                  {price['info2']}
                  </p>
                }             
                <p className={styles.bullet}>
                  {price['info3']}
                </p>
                
              </div>     
          </div>
        </div>
        <button onClick={e => handleCardClick(price['url'])} className={styles.planbutton}>
          <p className={styles.planlabel}>
            {"Order Today"}
          </p>
        </button>     
      </div>
    </>
  )
}

export default Paycard