import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Offer.module.sass";

const Offer = ({ className }) => {
  return (
    <div className={cn(className, styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={cn("stage", styles.stage)}>
          Get Started
        </div>
        <h2 className={cn("h1", styles.title)}>
            Take Charge of the game you love
        </h2>
        <div className={styles.text}>
          Join us in the modern coaching revolution. SparkmySport frees you from the confines of the gym, 
          empowering you to coach anytime, anywhere. We’re not just enhancing training; we’re building a 
          future where every athlete has the tools to reach their full potential. Embrace the future of sports and unleash greatness, 
          one play at a time
        </div>
        <Link className={cn("button", styles.contactbutton)} to="/contact">
          Contact Us
        </Link>
      </div>
    </div>
  );
};

export default Offer;
