import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import cn from "classnames";
import styles from "./Footer.module.sass";

const menu = [
    {
        title: "Home",
        url: "/",
    },
    {
        title: "Contact Us",
        url: "/contact",
    },
    {
        title: "Privacy Policy",
        url: "/privacy-policy",
    },
    {
        title: "Terms and Conditions",
        url: "/terms",
    },
];

// const socials = [
//     {
//         title: "instagram",
//         size: "16",
//         url: "https://www.instagram.com/sparkmysportz",
//     },
//     {
//         title: "linkedin",
//         size: "16",
//         url: "https://www.linkedin.com/in/ahebbale",
//     },
// ];

const Footer = () => {
    const { pathname } = useLocation();

    return (
        <footer className={styles.footer}>
            <div className={styles.body}>
                <div className={cn("container", styles.container)}>
                    <div className={styles.col}>
                        <div
                            className={cn(styles.item, {
                                [styles.active]: true,
                            })}
                        >
                            <div className={styles.menu}>
                                {menu.map((x, index) => (
                                    <NavLink
                                        className={cn(styles.link, {
                                            [styles.active]: pathname === x.url,
                                        })}
                                        to={x.url}
                                        key={index}
                                    >
                                        {x.title}
                                    </NavLink>
                                ))}
                            </div>
                        </div>
                    </div>
                    {/* <div className={styles.col}>
                        <div className={styles.category}>contact</div>
                        <div className={styles.info}>
                            <p>2818 Garden Creek Circle</p>
                            <p>Pleasanton, CA 94588</p>
                        </div>
                    </div> */}
                </div>
            </div>
            <div className={styles.foot}>
                <div className={cn("container", styles.container)}>
                    <div className={styles.copyright}>
                        Copyright © 2024 Sparkmysport Inc. All rights reserved
                    </div>
                    <div className={styles.socials}>
                        {/* {socials.map((x, index) => (
                            <a
                                className={styles.social}
                                href={x.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                key={index}
                            >
                                <Icon name={x.title} size={x.size} />
                            </a>
                        ))} */}
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
