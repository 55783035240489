import React from "react"
import styles from "./Privacy.module.sass"

const Privacy = () => {
  return(
    <>
      <div className={styles.page}>
        <div className={styles.title}>
          <p className={styles.blurb}>
            Your Data, Your Rights
          </p>
          <p className={styles.heading}>
            Privacy Policy
          </p>
        </div>
        <div className={styles.textarea}>
          <p className={styles.sectionheader}>
            Our Commitment to Privacy
          </p>
          <p className={styles.description}>
            {"This notice describes our Privacy Policy. Our privacy policy explains how Skillteck Inc. (“Skillteck”) collects, uses, and protects the personal information obtained through the use of our Skillteck mobile application, Spark My Sport (“App”), our website www.sparkmysport.com (“Site”) and the services available through both the App and Site (collectively, “Services”). We provide a unique mobile application experience to assist coaches and athletes in coaching and improving their skill level through our proprietary tools and technologies. By visiting the Site, using or downloading the App, or using any of our Services, you agree that your personal information will be handled as described in this Privacy Policy. Your use of our Site, App, or Services, and any dispute over privacy, is subject to this Policy and our Terms of Use, including its applicable limitations on damages and the resolution of disputes. The Skillteck Terms of Use are incorporated by reference into this Policy."}
          </p>
          <p className={styles.sectionheader}>
            What Information is Collected.
          </p>
          <p className={styles.description}>
            {"We collect information about you directly from you and from third parties, as well as automatically through your use of our Site, App or Services."}
          </p>
          <p className={styles.descriptionbold}>
            {"Information We Collect Directly From You."}
          </p>
          <li className={styles.list}>
            {"When you register with our App, you must provide certain information, including your username and password, first and last name and email address. We may also ask you to upload photos for your profile and may collect any information (including location metadata and inferred characterizations or data) contained in these files. You may provide additional information about yourself (such as your school, current city, date of birth, specific athlete information, etc.) in order to build your Skillteck profile and identify. We will not request any “sensitive personal data” under applicable law, such as your race, ethnicity, religion, philosophical or political views, but this information may be provided in your 'about me' bio. By affirmatively providing Skillteck with this information, you explicitly consent to our use of it for the purposes as stated herein. Any personally identifiable information you provide in the publicly viewable portion of your profile can be seen by any other user. Please ensure you do not share any personally identifiable information in these areas that you do not want other users to see. We collect and process your messages with other users as part of the operation of Skillteck."}
          </li>
          <li className={styles.list}>
            {"Text Messages/Push Notifications: We may send text messages or push notifications to your mobile phone for purposes of communicating with you about Skillteck. By registering with Skillteck and providing your phone number, you consent to Skillteck sending you text messages via an automatic telephone dialing system. Your use of Skillteck is not contingent upon agreeing to receive text messages."}
          </li>
          <p className={styles.descriptionbold}>
            {"Information We Collect Automatically. We automatically collect information about your use of our Services through cookies, web beacons, and other technologies, including technologies designed for mobile applications. We combine this information with other information we collect about you. Please see the section “Cookies and Other Tracking Mechanisms” below for more information."}
          </p>
          <li className={styles.list}>
            {"Web pages you view or links you click."}
          </li>
          <li className={styles.list}>
            {"The referring URL, or the webpage or mobile application that led you to our Site;"}
          </li>
          <li className={styles.list}>
            {"Technical information about your computer or mobile device (e.g., type of device, web browser or operating system, IP address) to analyze trends, administer the site, prevent fraud, track visitor movement in the aggregate, and gather broad demographic information."}
          </li>
          <li className={styles.list}>
            {"Language information;"}
          </li>
          <li className={styles.list}>
            {"Activities within the App; and the length of time that you are logged into our App;"}
          </li>
          <li className={styles.list}>
            {"Geolocation Information. We currently do not collect any location information from your mobile device. However, we may collect such information using your device's GPS tracking. We will notify you in the event we begin to collect this information."}
          </li>
          <li className={styles.list}>
            {"Usage Information about how long and in what manner you use Skillteck, which services and features you use and any content that you generate through and post on Skillteck. This includes browsing and search history (including profiles you have viewed). We link your user information with your activity on Skillteck across all your devices using your email, phone number or similar information."}
          </li>
          <p className={styles.descriptionbold}>
            {"Information we obtain from other sources."}
          </p>
          <li className={styles.list}>
            {"Facebook Profile, Google Account or Apple ID. In order to register with our App, you may be asked to sign in using your Facebook Profile, Google or Apple ID login. If you do so, you are authorizing us to access and process certain Google or Apple ID account information, including information about you, any photos and your account. If you register through Facebook, you are authorizing us to access and process certain Facebook account information, including personal information about you. By allowing us to access your Facebook account, you understand that we may obtain and process certain information from your Facebook account, including your name, email address, birthday, current city, pictures stored on Facebook. We only obtain information from your Facebook, Google or Apple ID account that you specifically authorize and grant us permission to obtain."}
          </li>
          <li className={styles.list}>
            {"Other third-party services may be able to collect information about you, including information about your activity on Skillteck, and they may notify your connections on the third-party services about your use of Skillteck, in accordance with their privacy policies. We may receive the information described in this Privacy Policy from third party services, such as analytics providers."}
          </li>
          <li className={styles.list}>
            {"Users of Skillteck may provide us with information about you, including through customer support inquiries."}
          </li>
          <p className={styles.sectionheader}>
            {"How and When the Information is Used"}
          </p>
          <p className={styles.description}>
            {"Certain features of our Services are available only on our App. If you have any questions about which features you can use on which platform, please feel free to contact us at support@sparkmysport.com. Currently, we use your information, on the Site, the App or both, for the following purposes:"}
          </p>
          <li className={styles.list}>
            {"Facilitate contact and sharing functions through Skillteck users."}
          </li>
          <li className={styles.list}>
            {"Investigate and prevent fraud and other illegal activities."}
          </li>
          <li className={styles.list}>
            {"Provide, maintain, and improve Skillteck and our overall business."}
          </li>
          <li className={styles.list}>
            {"To help users identify assignments by using your device's location information."}
          </li>
          <li className={styles.list}>
            {"To provide our Services to you, to communicate with you about your use of our Services, to respond to your inquiries, and for other customer service purposes."}
          </li>
          <li className={styles.list}>
            {"To tailor the content and information that we may send or display to you, to offer location customization, and personalized help and instructions, and to otherwise personalize your experiences while using the Site, App or our Services."}
          </li>
          <li className={styles.list}>
            {"For marketing and promotional purposes. For example, we may use your information, such as your email address, to send you news and newsletters, special offers, and promotions, or to otherwise contact you about services or information we think may interest you. We also may use the information that we learn about you to assist us in advertising our Services on third party websites or mobile applications."}
          </li>
          <li className={styles.list}>
            {"To better understand how users access and use our Site, App and Services, both on an aggregated and individualized basis, in order to improve our Site, App and Services and respond to user desires and preferences, and for other research and analytical purposes."}
          </li>
          <li className={styles.list}>
            {"To protect our own rights and interests, such as to resolve any disputes, enforce our Terms of Use or to respond to legal process."}
          </li>
          <li className={styles.list}>
            {"In addition to the uses outlined above, by using Skillteck, you agree to allow us to anonymously use the information from you and your experiences to continue our research into successful relationships, including how to create and foster these relationships, so that we may continue to improve the Skillteck experience. This research may be published in our blogs or on social media. However, all of your responses will be kept anonymous, and we assure you that no personal information will be published."}
          </li>
          <p className={styles.sectionheader}>
            {"How We Share Your Information"}
          </p>
          <p className={styles.description}>
            {"We may share your information, including personal information, as follows:"}
          </p>
          <li className={styles.description}>
            {"Skillteck Users. Our users and any information that you post to the Services, including, without"}
          </li>
          <li className={styles.description}>
            {"Service Providers. We will not sell, trade, or rent your personally identifiable information to others. However, we do provide some of our services through contractual arrangements made with affiliates, service providers, partners and other third parties (“Service Providers”). Our Service Providers include Facebook, Google, Apple, and Twillio. We use Facebook, Google and Apple to allow users to sign up and login to our App. We use Twillio to provide all email services and they may receive your name and email to provide their services. We may also use Google Analytics to evaluate usage of our Services to perform tasks between our Services, or to deliver Services to you."}
          </li>
          <p className={styles.description}>
            {"We may also share information in the following circumstances:"}
          </p>
          <li className={styles.list}>
            {"Business Transfers. If we are acquired by or merged with another company, if substantially all of our assets are transferred to another company, or as part of a bankruptcy proceeding, we may transfer the information we have collected from you to the other company."}
          </li>
          <li className={styles.list}>
            {"In Response to Legal Process. We also may disclose the information we collect from you in order to comply with the law, a judicial proceeding, court order, or other legal process, such as in response to a court order or a subpoena."}
          </li>
          <li className={styles.list}>
            {"To Protect Us and Others. We also may disclose the information we collect from you where we believe it is necessary to investigate, prevent, or take action regarding illegal activities, suspected fraud, situations involving potential threats to the safety of any person, violations of our Terms of Use or this Privacy Policy, or as evidence in litigation in which we are involved."}
          </li>
          <li className={styles.list}>
            {"Aggregate and De-Identified Information. We may share aggregate or de-identified information about users with third parties for marketing, research or similar purposes."}
          </li>
          <p className={styles.sectionheader}>
            {"How We Protect Your Information"}
          </p>
          <p className={styles.description}>
            {"To protect your personal identifiable information, we take reasonable precautions and follow industry standard SSL/TLS end-to-end encryption of data in transit to make sure it is not inappropriately lost, misused, accessed, disclosed, altered or destroyed. If you provide us with your financial or any other personal information, the information is encrypted using industry standard protections in our database. No payment information is ever stored in our database, as it is solely processed through third parties and is only temporarily stored during the processing of payment. All other information we collect may be stored for two years post your account termination, unless we receive a request by you to remove such information sooner."}
          </p>
          <p className={styles.description}>
            {"Although we have implemented commercially reasonable precautions to protect the information we collect from loss, misuse, and unauthorized access, disclosure, alteration, and destruction, please be aware that despite our best efforts, no data security measures can guarantee 100% security. You should take steps to protect against unauthorized access to your password, phone, and computer by, among other things, signing off after using a shared computer, choosing a robust password that nobody else knows or can easily guess, and keeping your log-in and password private. We are not responsible for any lost, stolen, or compromised passwords or for any activity on your account via unauthorized password activity."}
          </p>
          <p className={styles.sectionheader}>
            {"Our Use of Cookies and Other Tracking Mechanisms "}
          </p>
          <p className={styles.description}>
            {"We and our third party Service Providers use cookies and other tracking mechanisms to track information about your use of our Site, App or Services. We may combine this information with other personal information we collect from you."}
          </p>
          
          <p className={styles.descriptionbold}>
            {"WHAT ARE COOKIES?"}
          </p>
          <p className={styles.description}>
            {"A cookie is a small text file which is sent to your computer or mobile device by the web server so that a website can remember some information about your browsing activity on the Site. The cookie will collect information relating to your use of the Site or App, such as information about your device and demographic data."}
          </p>
          <p className={styles.description}>
            {"In addition to cookies, our Site or App may use web beacons. Web beacons allow us to count the number of users who have visited or accessed the App or Site and to recognize users by accessing our cookies. We may employ web beacons to facilitate App administration and navigation, to track the actions of users of the Skillteck, to compile aggregate statistics about Skillteck usage and response rates, and to provide an enhanced experience for users of our App. We may also include web beacons in HTML-formatted e-mail messages that we send to determine which e-mail messages were opened. A web beacon is often invisible because it is only 1 x 1 pixel in size with no color. A web beacon can also be known as a web bug, 1 by 1 GIF, invisible GIF and tracker GIF."}
          </p>
          <p className={styles.descriptionbold}>
            {"WHAT ARE THE DIFFERENT TYPES OF COOKIES AND HOW DO WE USE THEM?"}
          </p>
          <li className={styles.list}>
            {"Essential: These are cookies which are essential for the running of our App. Without these cookies, parts of our App would not function. These cookies do not track where you have been on the internet and do not gather information about you that could be used for marketing purposes. Examples of how we may use essential Cookies include: \nSetting unique identifiers for each unique visitor, so that user numbers can be analyzed."}
          </li>
          <li className={styles.list}>
            {"Functional: hese cookies are used to remember your preferences and login information on our App and to provide enhanced, more personal features. The information collected by these cookies is usually anonymized, so we cannot identify you personally. Functional cookies do not track your internet usage or gather information which could be used for selling advertising."}
          </li>
          <p className={styles.description}>
            {"Examples of how we may use functional Cookies include:"}
          </p>
          <li className={styles.list}>
            {"Gathering data about visits to our App, including numbers of visitors and visits, length of time spent on the App, or where visitors have come from."}
          </li>
          <li className={styles.list}>
            {"Eliminating the need for returning users to re-enter their login details."}
          </li>
          <li className={styles.list}>
            {"Analytical Performance: Analytical performance cookies are used to monitor the performance of our Site or App, for example, to determine the number of page views and the number of unique users our App has. We use this information to improve user experience or identify areas of the App which may require maintenance. The information is anonymous (i.e. it cannot be used to identify you and does not contain personal information such as your name and email address) and it is only used for statistical purposes. Examples of how we may use analytical cookies include: \n Measuring users' behavior \n Analyze which pages are viewed and how long for and which links are followed to better develop our App."}
          </li>
          <li className={styles.list}>
            {"Third Party Cookies: You may notice on some pages of our App that cookies have been set that are not related to us. When you visit a page with content embedded from these third-party service providers may set their own cookies on your mobile device. We do not control the use of these third-party cookies and cannot access them due to the way that cookies work, as cookies can only be accessed by the party who originally set them. Please check the third-party websites or mobile applications for more information about these cookies."}
          </li>
          <li className={styles.list}>
            {"Disabling Cookies: Most web browsers automatically accept cookies, but if you prefer, you can edit your browser options to block them in the future. The Help portion of the toolbar on most browsers will tell you how to prevent your computer from accepting new cookies, how to have the browser notify you when you receive a new cookie, or how to disable cookies altogether. If you choose to refuse, disable, or delete cookies, some of the functionality of Skillteck may no longer be available to you. Without this information, we are not able to provide you with all the requested services, and any differences in services are related to your information."}
          </li>
          <li className={styles.descriptionbold}>
            {"Some browsers transmit “do-not-track” signals to websites. Because of differences in how browsers incorporate and activate this feature, it is not always clear whether users intend for these signals to be transmitted, or whether they even are aware of them. We currently do not take action in response to these signals."}
          </li>
          <li className={styles.list}>
            {"Clear GIFs, pixel tags and other technologies: Clear GIFs are tiny graphics with a unique identifier, similar in function to cookies. In contrast to cookies, which are stored on your computer's hard drive, clear GIFs are embedded invisibly on web pages. We may use clear GIFs (a.k.a. web beacons, web bugs or pixel tags), in connection with our Site, App and Services to, among other things, track the activities of Site visitors and App users, help us manage content, and compile statistics about Site and App usage. We and our third party service providers also use clear GIFs in HTML emails to our customers, to help us track email response rates, identify when our emails are viewed, and track whether our emails are forwarded."}
          </li>
          <p className={styles.sectionheader}>
            {"Third Party Analytics"}
          </p>
          <p className={styles.description}>
            {"We use automated devices and applications, such as Google Analytics, to evaluate usage of our Site and App. We also may use other analytic means to evaluate our Services. We use these tools to help us improve our Services, performance and user experiences. These entities may use cookies and other tracking technologies to perform their services. We do not share your personal information with these third parties."}
          </p>
          <p className={styles.sectionheader}>
            {"Third-Party Links"}
          </p>
          <p className={styles.description}>
            {"When you click on links on our App or Site, they may direct you away from our App or Site. We are not responsible for the privacy practices of other websites or mobile applications and encourage you to read their individual privacy policies. If you visit a third-party website or mobile application link from our App or Site, you do so at your own risk."}
          </p>
          <p className={styles.sectionheader}>
            {"How You Can Access Your Information"}
          </p>
          <p className={styles.description}>
            {"You may modify personal information that you have submitted by logging into your account and updating your profile information. Please note that copies of information that you have updated, modified or deleted may remain viewable in cached and archived pages of the Site or App for a period of time."}
          </p>
          <p className={styles.description}>
            {"Although we describe much of the following processes throughout this Privacy Policy, please do not hesitate to email us at support@sparkmysport.com to receive the following information:"}
          </p>
          <li className={styles.list}>
            {"What personal information pertaining to you is being processed"}
          </li>
          <li className={styles.list}>
            {"Why this information is being processed"}
          </li>
          <li className={styles.list}>
            {"Who has access to this personal information about you"}
          </li>
          <li className={styles.list}>
            {"How this personal information is being used in automated decisions"}
          </li>
          <li className={styles.list}>
            {"What processes are using this information"}
          </li>
          <p className={styles.sectionheader}>
            {"CAN-SPAM Act and Opting Out of Emails"}
          </p>
          <p className={styles.description}>
            {"The CAN-SPAM Act is a law that sets the rules for commercial email, establishes requirements for commercial messages, gives recipients the right to have emails stopped from being sent to them, and spells out tough penalties for violations. We collect your email address in order to:"}
          </p>
          <li className={styles.list}>
            {"Send information, respond to inquiries, and/or other requests or questions."}
          </li>
          <li className={styles.list}>
            {"We may also send you additional information related to your account or our Services."}
          </li>
          <li className={styles.list}>
            {"Market to our mailing list or continue to send emails to our users after an account has been created."}
          </li>
          <li className={styles.list}>
            {"To be in accordance with CANSPAM we agree to the following:"}
          </li>
          <li className={styles.list}>
            {"NOT use false, or misleading subjects or email addresses"}
          </li>
          <li className={styles.list}>
            {"Identify the message as an advertisement in some reasonable way"}
          </li>
          <li className={styles.list}>
            {"Include the physical address of our business or site headquarters"}
          </li>
          <li className={styles.list}>
            {"Monitor third party email marketing services for compliance."}
          </li>
          <li className={styles.list}>
            {"Honor opt-out/unsubscribe requests quickly"}
          </li>
          <li className={styles.list}>
            {"Allow users to unsubscribe by using the link at the bottom of each email"}
          </li>
          <p className={styles.description}>
            {"We may send periodic promotional or informational emails to you. You may opt-out of such communications by following the opt-out instructions contained in the email. Please note that it may take up to 10 business days for us to process opt-out requests. Even if you opt-out, we may still communicate with you about your account or any Services you receive from us."}
          </p>
          <p className={styles.sectionheader}>
            {"Children"}
          </p>
          <p className={styles.description}>
            {"The Services are not intended for use by children under the age of 13. Skillteck does not allow individuals under the age of 13 to create an account, nor do we knowingly collect or use any personal information from such children. If you are under the age of 13, do not submit any information to our App or Site. If we learn that we collected personal information from children under the age of 13, we will take steps to delete that information as soon as possible."}
          </p>
          <p className={styles.sectionheader}>
            {"International Data Transfer"}
          </p>
          <p className={styles.description}>
            {"Skillteck is a United States company. If you are located outside the United States and choose to provide information to us, Skillteck transfers and stores personal information to the United States for processing. The U.S. may not have the same data protection laws as the country in which you initially provided the information. When we transfer your information to the U.S., we will protect it as described in this Privacy Policy. By visiting the Site, the App, using our Services or providing Skillteck with any information, you fully understand and unambiguously consent to this transfer, processing and storage of your information in the United States."}
          </p>
          <p className={styles.description}>
            {"We will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy. Unfortunately, the transmission of information via the internet is not completely secure. Although we will do our best to protect your personal data, we cannot guarantee the security of your data transmitted to our Site or App; any transmission is at your own risk. Once we have received your information, we will use strict procedures and security features in an effort to prevent loss, theft and unauthorized access, use and disclosure."}
          </p>
          <p className={styles.sectionheader}>
            {"For our European Customers and Visitors"}
          </p>
          <p className={styles.description}>
            {`By using our site, you consent to any transfer of your Personal Information out of Europe, UK, or Switzerland for processing in the US or other countries. 
  
  a.  	We will comply with the EU Standard Contractual Clauses with respect to the transfer of Personal Data from the EU to the US for processing. If there is any conflict between the terms and conditions in this Privacy Policy and your rights under the EU Standard Contractual Clauses, the terms and conditions in the EU Standard Contractual Clauses will govern. For the purposes of this Privacy Policy, “EU Standard Contractual Clauses” mean the standard contractual clauses for the transfer of personal data to processors established in the US. (Commission Decision 2010/87/EC).
  i.                 Obligations of the data importer (processors)
  The data importer agrees and warrants:
  (a)    to process the personal data only on behalf of the data exporter and in compliance with its instructions and the Clauses; if it cannot provide such compliance for whatever reasons, it agrees to inform promptly the data exporter of its inability to comply, in which case the data exporter is entitled to suspend the transfer of data and/or terminate the contract;
  (b)	that it has no reason to believe that the legislation applicable to it prevents it from fulfilling the instructions received from the data exporter and its obligations under the contract and that in the event of a change in this legislation which is likely to have a substantial adverse effect on the warranties and obligations provided by the Clauses, it will promptly notify the change to the data exporter as soon as it is aware, in which case the data exporter is entitled to suspend the transfer of data and/or terminate the contract;
  (c)	that it has implemented the technical and organizational security measures before processing the personal data transferred;
  (d)	that it will promptly notify the data exporter about:
  o   any legally binding request for disclosure of the personal data by a law enforcement authority unless otherwise prohibited, such as a prohibition under criminal law to preserve the confidentiality of a law enforcement investigation,
  o   any accidental or unauthorized access, and
  o   any request received directly from the data subjects without responding to that request, unless it has been otherwise authorized to do so;
  (e)	to deal promptly and properly with all inquiries from the data exporter relating to its processing of the personal data subject to the transfer and to abide by the advice of the supervisory authority with regard to the processing of the data transferred;
  (f) 	at the request of the data exporter to submit its data processing facilities for audit of the processing activities covered by the Clauses which shall be carried out by the data exporter or an inspection body composed of independent members and in possession of the required professional qualifications bound by a duty of confidentiality, selected by the data exporter, where applicable, in agreement with the supervisory authority;
  (g)	to make available to the data subject upon request a copy of the Clauses, or any existing contract for sub-processing, unless the Clauses or contract contain commercial information, in which case it may remove such commercial information, with the exception of Appendix 2 which shall be replaced by a summary description of the security measures in those cases where the data subject is unable to obtain a copy from the data exporter;
  (h)	that, in the event of sub-processing, it has previously informed the data exporter and obtained its prior written consent;
  ii.               Obligations of the data exporter
  The data exporter agrees and warrants:
  (a)    that the processing, including the transfer itself, of the personal data has been and will continue to be carried out in accordance with the relevant provisions of the applicable data protection law (and, where applicable, has been notified to the relevant authorities of the Member State where the data exporter is established) and does not violate the relevant provisions of that State;
  (b)	that it has instructed and throughout the duration of the personal data processing services will instruct the data importer to process the personal data transferred only on the data exporter's behalf and in accordance with the applicable data protection law and the Clauses;
  (c)	that the data importer will provide sufficient guarantees in respect of the technical and organizational security measures;
  (d)	that after assessment of the requirements of the applicable data protection law, the security measures are appropriate to protect personal data against accidental or unlawful destruction or accidental loss, alteration, unauthorized disclosure or access, in particular where the processing involves the transmission of data over a network, and against all other unlawful forms of processing, and that these measures ensure a level of security appropriate to the risks presented by the processing and the nature of the data to be protected having regard to the state of the art and the cost of their implementation;
  (e)	that it will ensure compliance with the security measures;
  (f) 	that, if the transfer involves special categories of data, the data subject has been informed or will be informed before, or as soon as possible after, the transfer that its data could be transmitted to a third country not providing adequate protection within the meaning of Directive 95/46/EC;
  (g)	to make available to the data subjects upon request a copy of the Clauses, with a summary description of the security measures, as well as a copy of any contract for sub-processing services which has to be made in accordance with the Clauses, unless the Clauses or the contract contain commercial information, in which case it may remove such commercial information; and 
  (h)	that, in the event of sub-processing, the processing activity is carried out in at least the same level of protection for the personal data and the rights of the data subject as the data importer under the Clauses.
  iii.              Liability
  (a)	The parties agree that any data subject, who has suffered damage as a result of any breach of the obligations referred above by any party or sub-processor is entitled to receive compensation from the data exporter for the damage suffered.
  (b)	If a data subject is not able to bring a claim for compensation in accordance with paragraph a against the data exporter, arising out of a breach by the data importer or his sub-processor of any of their obligations referred to above, because the data exporter has factually disappeared or ceased to exist in law or has become insolvent, the data importer agrees that the data subject may issue a claim against the data importer as if it were the data exporter, unless any successor entity has assumed the entire legal obligations of the data exporter by contract of by operation of law, in which case the data subject can enforce its rights against such entity.
  iv.               
    
  b.  	If you are a resident of or a visitor to Europe, you have certain rights with respect to the processing of your Personal Data, (referred here as Personal Information), as defined in the GDPR.
    
  c.  	Please note that in some circumstances, we may not be able to fully comply with your request, or we may ask you to provide us with additional information in connection with your request, which may be Personal Information, for example, if we need to verify your identity or the nature of your request. 
    
  d.  	In such situations, however, we will still respond to let you know of our decision. As used herein, “Personal Information” means any information that identifies you as an individual, such as name, address, email address, IP address, phone number, business address, business title, business email address, company, etc. 
  
  e.  	To make any of the following requests, please contact us via email at support@sparkmysport.com.
    
  i.                 Access: You can request more information about the Personal Information we hold about you. You can also request a copy of the Personal Information.
  ii.               Rectification: If you believe that any Personal Information we are holding about you is incorrect or incomplete, you can request that we correct or supplement such data. Please contact us as soon as possible upon noticing any such inaccuracy or incompleteness.
  iii.              Objection: You can contact us to let us know that you object to the collection or use of your Personal Information for certain purposes.
  iv.              Erasure: You can request that we erase some or all of your Personal Information from our systems.
  v.                Restriction of Processing: You can ask us to restrict further processing of your Personal Information.
  vi.              Portability: You have the right to ask for a copy of your Personal Information in a machine-readable format. You can also request that we transmit the data to another entity where technically feasible.
  vii.            Withdrawal of Consent: If we are processing your Personal Information based on your consent (as indicated at the time of collection of such data), you have the right to withdraw your consent at any time. Please note, however, that if you exercise this right, it may limit your ability to use some/ all of our Services or Platform and you may have to then provide express consent on a case-by-case basis for the use or disclosure of certain of your Personal Information, if such use or disclosure is necessary to enable you to utilize some or all of our Services and Platform.
  viii.           Right to File Complaint: You have the right to lodge a complaint about our practices with respect to your Personal Information with the supervisory authority of your country or EU Member State. Please go to https://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm to locate your Data Protection Authority.
  ix.              Response. We will respond to your inquiry within thirty (30) days of the receipt. `}
          </p>
          <p className={styles.sectionheader}>
            {"California Residents Privacy Rights"}
          </p>
          <p className={styles.description}>
            {"The California Consumer Privacy Act (CCPA) provides consumers who are residents of the State of California with specific rights related to their personal information, subject to certain exceptions. It also requires us to disclose the information we collect, the purposes for which we collect it, and what we share and disclose."}
          </p>
          <p className={styles.description}>
            {"California Civil Code § 1798.83, also known as the “Shine the Light” law, provides California residents with the right to request, once per year, without charge, information from website operators and owners regarding the Personal Information that they collect online and offline, if any, and the third parties, including affiliates, with which they have shared this information during the preceding calendar year, for the direct marketing purposes of the third party. If such information is collected and shared with third parties for their direct marketing purposes, the website operator or owner is required, upon request, to provide the list of categories of Personal Information they collect, if any, and the names and addresses of all third parties with which it has shared such information for direct marketing purposes."}
          </p>
          <p className={styles.description}>
            {"A website operator or owner may meet its obligations, and be exempt from such disclosures, under the Shine the Light law, if it provides California residents the right to opt-out of, and thereby prohibit, the website operator or owner from sharing such Personal Information with third parties including its affiliates, for the direct marketing purposes of the third party."}
          </p>
          <p className={styles.description}>
            {"In accordance with California Civil Code section 1798.83(c)(2), Skillteck complies with California 's “Shine the Light” law by providing all of its users, including its California users, with a cost-free method to opt-out of the sharing of Personal Information with all third parties for the direct marketing of those third parties, by not disclosing to third parties, for their direct marketing purposes the Personal Information of any customer if the customer has exercised the option to prevent such disclosure, and by disclosing these policies in its Privacy Policy. Consequently, Skillteck is not obligated under Shine the Light to provide California users, who have requested such information, a list of the categories of the Personal Information disclosed by Skillteck to third parties for their direct marketing purposes or the names and addresses of the third parties to which Skillteck has shared such Personal Information for their direct marketing purposes during the preceding calendar year."}
          </p>
          <p className={styles.description}>
            {"California residents may have further rights related to the handling of their Personal Information under the California Consumer Privacy Act. While Skillteck does not “sell” your Personal Information in the traditional sense, it may share your information with third parties. California residents may have certain rights related to this information, including the right to ask that we “Do Not Sell Your Personal Information,” the right to ask that we Delete your Personal Information, or the right to request access to the categories or specific pieces of Personal Information we have collected about you. California residents also have the right to not be treated differently by Skillteck in response to your decision to exercise any of these rights."}
          </p>
          <p className={styles.description}>
            {"To learn more about these rights, to opt-out of our sharing of your Personal Information, and to exercise other rights that apply, please contact Skillteck by emailing us at support@sparkmysport.com. As part of your request, we may ask for certain information including your name and account information, or the manner in which you have previously interacted with Skillteck. We will use this information only to contact you about your request and to verify your request. You may also designate an authorized agent to submit a request on your behalf."}
          </p>
          <p className={styles.sectionheader}>
            {"Information for Nevada Consumers:"}
          </p>
          <p className={styles.description}>
            {"Nevada law requires website operators to provide a way for Nevada consumers to opt out of the sale of certain information that the website or mobile application operator may collect about them. This information is limited to direct identifiers, such as your name, postal address, and email address. When Nevada consumers opt out of the sale of their information, website operators must ensure that the information is not sold to another business that will further license or sell the information to others."}
          </p>
          <p className={styles.description}>
            {"If you are a Nevada resident and you would like to opt out of any possible sale of this information, please email us at support@sparkmysport.com. For all requests, you must put the statement “Your Nevada Privacy Rights” in the subject field of your request. You must also include your full name, street address, city, state, and zip code. We will not accept requests via telephone, postal mail, or facsimile, and we are not responsible for notices that are not labeled or sent properly, or that do not have complete information."}
          </p>
          <p className={styles.sectionheader}>
            {"Changes to Privacy Policy"}
          </p>
          <p className={styles.description}>
            {"Skillteck reserves the right to change this Privacy Policy from time to time as it sees fit and your continued use of the Site will signify your acceptance of any adjustment to this Privacy Policy. If there are any changes to our Privacy Policy, we will announce that these changes have been made on our home page and on other key pages on our Site. If there are any changes in how we use our customers' personal information, changes to our Privacy Policy will be posted on our Site 30 days prior to these changes taking place. You are therefore advised to re-read this Privacy Policy on a regular basis."}
          </p>
        </div>
      </div>
    </>
  )
}

export default Privacy