import React from "react";
import styles from "./Pricing.module.sass";
import { useState } from "react";
import cn from "classnames";

import Offer from "../../components/Offer";
import Paycard from "../../components/Paycard";
import PaycardInfo from "../../components/PaycardInfo";

const Pricing = () => {
  const [selectedUser, setUser] = useState(0);

  const eventPassInfo = [
    { type: "title", data: "Package Features" },
    { type: "label", data: "Watch live Games & On-Demand (wherever livestream is available)" },
    { type: "label", data: "Live Stats and Smart Analytics" },
    { type: "bullet", data: "Box Charts, Shot Charts, Heat Maps" },
    { type: "bullet", data: "Team & Player Comparison" },
    { type: "bullet", data: "Player Grading & Ranking Charts" },
    { type: "bullet", data: "Cumulative Season Stats" },
    { type: "bullet", data: "Player Evaluation" }
  ];

  const schoolInfo = [
    { type: "title", data: "Package Features" },
    { type: "label", data: "Watch live Games & On-Demand (wherever livestream is available)" },
    { type: "asterik", data: "*  Record or live stream games or practice from your phone. Film is stored in the cloud and privately shared between players, parents and coaches." },
    { type: "bullet", data: "Real-Time Game film exchange with teams, coaching staff, scouts, and fans" },
    { type: "bullet", data: "Share practice clips and plays between coaches and athletes for realtime feedback" },
    { type: "label", data: "Live Stats and Smart Analytics" },
    { type: "bullet", data: "Box Charts, Shot Charts, Heat Maps" },
    { type: "bullet", data: "Team & Player Comparison" },
    { type: "bullet", data: "Player Grading & Ranking Charts" },
    { type: "bullet", data: "Cumulative Season Stats" },
    { type: "bullet", data: "Player Evaluation" },
    { type: "label", data: "Scouting & Player Discovery" },
    { type: "bullet", data: "Build your brand through our recruitment profiles" },
    { type: "bullet", data: "Social media reels and player highlights" },
    { type: "bullet", data: "Player assessments and recommendations" },
    { type: "bullet", data: "Share & connect with college coaches & scouts" },
    { type: "label", data: "Feedback from Coaches & Communication Tools" },
    { type: "bullet", data: "Mark-up Tools for skills & game video analysis" },
    { type: "bullet", data: "Highlights area of improvement with sketch and voice annotations" },
    { type: "bullet", data: "Side-by-Side overlay" },
    { type: "bullet", data: "Slow motion, scrub, zoom and frame-by-frame motion analysis" }
  ];

  const schoolPriceData = [
    { type: "title1", data: "Additional services for Parents and Players on Participating Teams" },
    { type: "data", data: {title: "Player Recruitment profiles and Social Media Highlights", details: "Discover how Spark my Sport simplifies player evaluation, enhances coaching strategies and connects athletes with opportunities in the world of sports  ", price: "$299", url: "https://buy.stripe.com/28o6py3bMeqX5t6fZz"} },
  ];

  const eventPriceData = [
    { type: "title1", data: "Event Organizations Package" },
    { type: "label", data: "Monetize your events: Use Spark my Sport platform to make money with live streaming and live stats" },
    { type: "data", data: { title: "Event package (10 games)", details: "Game Highlights + Players Stats - Box chart, ranking chart and evaluation reports ", price: "$399", priceSpecificUnder: "$20 for each additional game", url: "https://buy.stripe.com/fZedS09Aafv16xa9B7" } },
    { type: "asterik", data: "*Easy setup instructions provided by Spark my Sport team" },
     
  ];

  const eventPriceData1 = [
    { type: "title1", data: "Spark My Sport Hosted Event" },
    { type: "label", data: "Packages for spark my sport hosted events" }

  ];

  const scoutPriceData = [
    { type: "data", data: { title: "Yearly Subscription", details: "* Only for College Coaches/Scouts ", price: "$999" , url: "https://buy.stripe.com/eVa7tC13E82zdZC4gS" } },
    { type: "title", data: "Discover Elite Talent" },
    { type: "label", data: "Our platform offers comprehensive tools to find young student athletes - access players profiles, stats, and highlights Streamline your scouting process to find the right fit efficiently, saving time and enhancing recruitment strategy."} 
  ];

  const schoolPrice = [
    { planName: "Plan 1", name: "Basic", price: "$599", info1: "Self-service", info2: "* Gain access to the Spark my Sport Platform. Film your games and log your stats using the Sparkmysport.", info3: "Season Package: 20 games or 50  recording hours", url: "https://buy.stripe.com/7sI9BK13E96DcVy3cK"},
    { planName: "Plan 2", name: "Professional", price: "$999", info1: "Automated Stats", info2: "*  Gain access to the Spark my Sport platform. Film your games and automated game stats.",  info3: "Season Package: 20 games or 50  recording hours", url: "https://buy.stripe.com/cN24hq6nY4QncVybJh" },
    { planName: "Plan 3", name: "Full Service", price: "$1,999", info1: "Automated Game filming + Stats", info2: "Full Service" , info3: "Season Package: 20 games or 50  recording hours", url: "https://buy.stripe.com/eVa8xG5jUbeLbRucNm"}
  ];

  const eventPassPrice = [
    { planName: "Plan 1", name: "One Day Pass", price: "$19.99", info1: "", info2: "", url: "https://buy.stripe.com/dR6g08bIiciP8FiaF9"},
    { planName: "Plan 2", name: "Weekend Pass", price: "$24.99", info1: "", info2: "", url: "https://buy.stripe.com/cN2g08aEe5UraNq9B6" } 
  ];

  return (
    <>
      <div className={styles.page}>
        <div className={styles.title}>
          <p className={styles.heading}>
            Discover - Develop - Exposure
          </p>
          <p className={styles.heading_mobile}>
            Discover Develop Exposure
          </p>
          <p className={styles.blurb}>
            Discover How Spark My Sport Simplifies Player Evaluation, Enhances<br className={styles.break} />
            Coaching Strategies And Connects Athletes With Opportunities In The World Of Sports
          </p>
        </div>
        <div className={styles.selection}>
          <div className={styles.buttonrow}>
            <button onClick={() => setUser(0)} className={cn(styles.userbutton, { [styles.active]: selectedUser === 0 })}>
              <p className={cn(selectedUser === 0 ? styles.userlabel_active : styles.userlabel_inactive)}>
                Event Pass
              </p>
            </button>
            <button onClick={() => setUser(1)} className={cn(styles.userbutton, { [styles.active]: selectedUser === 1 })}>
              <p className={cn(selectedUser === 1 ? styles.userlabel_active : styles.userlabel_inactive)}>
                School Teams/ AAU Clubs
              </p>
            </button>
            <button onClick={() => setUser(2)} className={cn(styles.userbutton, { [styles.active]: selectedUser === 2 })}>
              <p className={cn(selectedUser === 2 ? styles.userlabel_active : styles.userlabel_inactive)}>
                Scouts
              </p>
            </button>
          </div>
 
          {selectedUser === 0 && (
            <div className={styles.paycontainer}>
              <div className={styles.paycardrow}>
                <PaycardInfo data={eventPriceData} />
              </div>
              <div className={styles.paycardrow}>
                <div className={styles.paycardcol}>
                  <p className={styles.titlebold}>
                    Spark My Sport Hosted Event
                  </p>
                  <p className={styles.titleleft}>
                    Packages for spark my sport hosted events.
                  </p>
                  <div className={styles.paycardrow}>
                    <Paycard price={eventPassPrice[0]} border={true} />
                    <Paycard price={eventPassPrice[1]} border={true} />
                  </div>
                </div>
              </div>
              <div className={styles.paycardrow}>
                <PaycardInfo data={eventPassInfo} />
              </div>
            </div>
          )}
          {selectedUser === 1 && (
            <div className={styles.paycontainer}>
              <div className={styles.paycardrow}>
                <Paycard price={schoolPrice[0]} />
                <Paycard price={schoolPrice[1]} />
                <Paycard price={schoolPrice[2]} />
              </div>
              <div className={styles.paycardrow}>
                <PaycardInfo data={schoolPriceData} />
              </div>
              <div className={styles.paycardrow}>
                <PaycardInfo data={schoolInfo} />
              </div>
            </div>
          )}
          {selectedUser === 2 && (
            <div className={styles.paycontainer}>
              <div className={styles.paycardrow}>
                <PaycardInfo data={scoutPriceData} />
              </div>
            </div>
          )}
          
        </div>
      </div>
      <Offer className="section" />
    </>
  );
};

export default Pricing;
