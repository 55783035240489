import React, { useState } from "react";
import cn from "classnames";
import Modal from "react-modal";
import styles from "./HeroCard.module.sass";

// Ensure that the app element is set for accessibility
Modal.setAppElement("#root");

const HeroCard = ({ item, reverse }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div className={cn(styles.usercard, { [styles.reverse]: reverse })}>
      <div className={styles.videocontainer}>
        <div
          className={styles.videosection}
          onClick={openModal}
          style={{
            backgroundImage: `url(${item.thumbnailUrl})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            cursor: "pointer",
            position: "relative",
          }}
        >
          <div className={styles.playbutton}>
            <img src="/images/content/play.png" alt="Play Button" />
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Watch Video"
        className={styles.modal}
        overlayClassName={styles.overlay}
      >
  
        <div className={styles.videoWrapper}>
          <video className={styles.video} controls autoPlay>
            <source src={item.videoUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <button className={styles.closeButton} onClick={closeModal}>
          Close
        </button>
      </Modal>
    </div>
  );
};

export default HeroCard;