import React from "react"
import cn from "classnames"
import styles from "./Commitment.module.sass"

const Commitment = ({ className }) => {
  return (
    <div className={cn(className, styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.content}>
          <h2 className={styles.title}>Our Commitment</h2>
          <p className={styles.description}>
            Driven by a genuine passion for grassroots sports, our founders—former pro athletes—teamed up with
            young engineers, coaches, and parents to bring our vision to life.
          </p>
          <p className={styles.description}>
            We've bootstrapped and personally invested to reach this stage:
          </p>
          <ul className={styles.list}>
            <li className={styles.listItem}>
              Securing a U.S. patent for our innovative coaching platform.
            </li>
            <li className={styles.listItem}>
              Last year, AAU teams and two of the three schools in our pilot program won CIF state championships.
            </li>
          </ul>
        </div>

        <div className={styles.imageContainer}>
          <img
            src= "/images/content/patent.svg"
            alt="US Patent Document"
            className={styles.patentImage}
          />
        </div>
      </div>
    </div>
  )
}

export default Commitment