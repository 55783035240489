import React from "react";
import cn from "classnames";
import styles from "./ValueProps.module.sass";
import ScrollParallax from "../ScrollParallax";
import HeroCard from "../Features/HeroCard";
const heroItems = [
  {
    title: "Spark My Sport",
    header: "Unlock your young athlete's potential",
    content:
      "Our innovative platform showcases young athletes to college scouts and pro coaches, professionally displaying their achievements, skills, and stats to help them stand out in sports recruitment.",
    reverse: false,
    videoUrl: "https://skillteck-v2.s3.us-west-1.amazonaws.com/videos/2873/library/trim.9A44BC8F-B141-4F00-973E-845789F23A88.mp4",
    thumbnailUrl: "/images/content/videotraining.svg",
  },
];

const items = [
  {
    title: "Dynamic Interface",
    content:
      "Monitor real-time progress, access detailed analytics, and receive performance updates during practices and games with our dynamic interface.",
    icon: '<Image className={styles.icon} src="/icons/card.png" />',
  },
  {
    title: "Targeted Coaching",
    content:
      "Stay informed about athletes' evolving skills and achievements, enabling targeted coaching and better decision-making in coaching and scouting.",
    icon: '<Image className={styles.icon} src="/icons/ball.png" />',
  },
  {
    title: "In-Depth Profiles",
    content:
      "View detailed profiles showcasing each player's strengths, game statistics, and progress over time, all in one place.",
    icon: '<Image className={styles.icon} src="/icons/person.png" />',
  },
  {
    title: "Actionable Insights",
    content:
      "Get performance evaluations with real-time stats and video highlights from games and practices for informed coaching decisions.",
    icon: '<Image className={styles.icon} src="/icons/graph.png" />',
  },
  {
    title: "Track Progress",
    content:
      "Easily track the continuous development and performance updates of young athletes to monitor their growth and improvement.",
    icon: '<Image className={styles.icon} src="/icons/linegraph.png" />',
  },
  {
    title: "Search and Filter",
    content:
      "Quickly find athletes by specific skills, stats, and other critical criteria with our powerful search and filter tools.",
    icon: '<Image className={styles.icon} src="/icons/search.png" />',
  },
];

const ValueProps = ({ className }) => {
  const videoOptions = {
    playerVars: {
      autoplay: 0,
    },
  };

  return (
    <div className={cn(className, styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.head}>
          <div className={cn("stage-small", styles.stage)}>Our Platform</div>
          <div className={cn("h2", styles.title)}>
            Unlock the Power of Video in Training
          </div>
          <div className={styles.text}>
          By integrating video into every aspect of training, we deliver precise feedback, in-depth analysis, 
          and seamless communication between coaches, athletes, and even parents - Enhancing the entire 
          training experience
          </div>
        </div>
        <div className={styles.videoWrapper}>
          <div className={styles.videoContainer}>
            <HeroCard item={heroItems[0]} />
          </div>
        </div>
        <div className={styles.list}>
          {items.map((x, index) => (
            <ScrollParallax className={styles.item} key={index}>
              <div
                className={styles.icon}
                dangerouslySetInnerHTML={{ __html: x.icon }}
              ></div>
              <div className={styles.category}>{x.title}</div>
              <div className={styles.content}>{x.content}</div>
            </ScrollParallax>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ValueProps;
