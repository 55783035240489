import { Routes, Route } from "react-router-dom";
import "./styles/app.sass";
import Page from "./components/Page";
import Home from "./screens/Home";
import Pricing from "./screens/Pricing";
import Contact from "./screens/Contact";
import Terms from "./screens/Terms";
import Privacy from "./screens/Privacy";

function App() {
    return (
        <Routes>
            <Route path="/">
                <Route
                    index
                    element={
                        <Page>
                            <Home />
                        </Page>
                    }
                />
                <Route
                    path="pricing"
                    element={
                        <Page>
                            <Pricing />
                        </Page>
                    }
                />
                <Route
                    path="contact"
                    element={
                        <Page>
                            <Contact />
                        </Page>
                    }
                />
                <Route
                    path="terms"
                    element={
                        <Page page="terms">
                            <Terms />
                        </Page>
                    }
                />
                <Route
                    path="privacy-policy"
                    element={
                        <Page page="terms">
                            <Privacy />
                        </Page>
                    }
                />
            </Route>
            
        </Routes>
    );
}

export default App;
