import React from "react";
import styles from "./PaycardPriceInfo.module.sass";

const handleRedirect = (link) => {
  window.location.href = link;
};

const handleCardClick = (url) => {
  handleRedirect(url);
};

const PaycardPriceInfo = ({ data }) => {
  return (
    <div className={styles.cardContainer}>
      <div className={styles.cardContent}>
        <div className={styles.titleSection}>
          <p className={styles.title}>{data['title']}</p>
          <p className={styles.asterik}>{data['details']}</p>
        </div>
        <div className={styles.pricedesc}>
          <div className={styles.priceColumn}>
            <p className={styles.price}>{data.price}</p>
            {data.priceSpecificUnder && (
              <p className={styles.asterikprice}>{data.priceSpecificUnder}</p>
            )}
          </div>
          {data.priceSpecificSide && (
            <p className={styles.asterikprice}>{data.priceSpecificSide}</p>
          )}
        </div>
      </div>
      <div className={styles.buttondiv}>
        {data.url && (
          <button onClick={() => handleCardClick(data.url)} className={styles.planbutton}>
            <p className={styles.planlabel}>Order Today</p>
          </button>
        )}
      </div>
    </div>
  );
};

export default PaycardPriceInfo;