import React, { useState } from "react";
import cn from "classnames";
import styles from "./Features.module.sass";
import VideoCard from "./VideoCard";

const priceData = {                         
                    "title" : "Flexible Pricing Options",
                    "text" : "Get started for as low as $9.99 per game, with a maximum of $49.99 per game. A season subscription is required for full access.",
                    "subtext" :"We’re committed to giving back—sharing profits with partner businesses or donating a portion of proceeds to support nonprofit organizations."
                  }

const items = [
  {
    title: "",
    header: "Film Study",
    content: "Transform game footage into a powerful learning tool with intuitive markers, drawing features, voiceovers, and interactive comments. Coaches and athletes can analyze plays, ask questions, and get AI-driven feedback, making every video a masterclass in skill-building.",
    reverse: false,
    videoUrl: "https://skillteck-v2.s3.us-west-1.amazonaws.com/videos/2873/library/3A526E6D-F797-480F-B615-837BE45A2309.mp4",
    thumbnailUrl: "/images/content/tumbnail1.svg",
    priceData: priceData
  },
  {
    title: "",
    header: "AI Engine: Charts and Leaderboards",
    content: "Our AI-powered insights give real-time performance metrics and rankings, helping athletes understand where they stand and where to improve, sparking motivation and competition",
    reverse: true,
    videoUrl: "https://skilltech-v2-public.s3.us-west-1.amazonaws.com/website/NewPlayersVid.mp4",
    thumbnailUrl: "/images/content/tumbnail2.svg",
    priceData: priceData
  }, 
  {
    title: "",
    header: "AI Co-Pilot: Performance and Evaluation Reports",
    content: "Meet your personal AI Co-Pilot, delivering unique evaluations and grades that go beyond the surface. With detailed, tailored insights, athletes gain a clear path to peak performance.",
    reverse: false,
    videoUrl: "Add the co pilot video video here",
    thumbnailUrl: "/images/content/tumbnail3.svg",
    priceData: priceData
  },
  {
    title: "NO VIDEO",
    header: "Platform Services for Schools and AAU Programs",
    content: "Take your businesses and coaching online with our streaming and scouting services. Engage audiences, monetize events, and grow your brand effortlessly with tools designed to bring your business to the forefront of digital sports training",
    reverse: true,
    videoUrl: "https://skilltech-v2-public.s3.us-west-1.amazonaws.com/website/NewScoutsVid.mp4",
    thumbnailUrl:"/images/content/tumbnail4.svg",
    priceData: {                         
                "title" : "Email us for special Pricing Options",
                "text" : "",
                "subtext" :""
              }
  },
];

const Trainer = ({ className }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <div  id="features" className={cn(styles.section, className)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.head}>
          <div className={cn("stage-small", styles.stage)}>
            How it Works
          </div>
          <div className={cn("h2", styles.title)}>
            Experience the
            SparkMySport advantage
          </div>
          <div className={styles.text}>
            Discover how SparkMySport simplifies player evaluation, enhances coaching strategies, and connects athletes with opportunities in the world of sports.
          </div>
        </div>
        <div className={styles.list}>
          {items.map((x, index) => (
            <VideoCard item={x} key={index} reverse={index % 2 !== 0} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Trainer;
