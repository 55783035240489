import cn from "classnames";
import styles from "./Hero.module.sass";
import Image from "../../../components/Image";
import ScrollButton from "../../../components/ScrollButton";


const Hero = ({ scrollToRef }) => {

    return (
        <div className={styles.hero}>
            <div className={cn("container", styles.container)}>
                <div className={styles.wrap}>
                    <h1 className={styles.title}>
                        Welcome to the <span className={styles.firstWord}>future</span> of sports training
                    </h1>
                    <div className={styles.text}>
                        <p>Our goal is to harness the power of video to make training accessible, engaging, and impactful—empowering athletes to reach their fullest potential.</p>
                        
                        <p style={{ marginTop: '20px' }}>Join us as we transform the sports landscape - redefining training for the next generation.</p>
                    </div>
                </div>
                <ScrollButton
                    onScroll={() =>
                        scrollToRef.current.scrollIntoView({
                            behavior: "smooth",
                        })
                    }
                    className={styles.scroll}
                />
                <div className={styles.gallery}>
                    <div className={styles.preview}>
                        <Image
                            src="/images/content/hero.png"
                            srcDark="/images/content/hero.png"
                            alt="Watch"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Hero;
