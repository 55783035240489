import React from "react"
import styles from "./BasicPriceCard.module.sass"
import cn from "classnames";
import { Link } from "react-router-dom";

const BasicPriceCard = ({ data }) => {
  
  
  const renderPricedText = () => {
    // Check if text exists
    if (!data.text) return "";

    // Regular expression to match price patterns
    const regex = /(\$\d+\.\d+)/;
    const parts = data.text.split(regex);

    return parts.map((part, index) => {
      console.log("part :",part)
      if (part.match(regex)) {
        return <span key={index} className={styles.whitePrice}>{part}</span>;
      }
      return <span key={index}>{part}</span>;
    });
  };

  return (
    <>
      <div className={cn(styles.paycard, styles.card_active)}>
        <div className={styles.carddiv} />
        <div className={styles.carddesc}>
            <p className={styles.title}>
                {data.title}
            </p>

            <p className={styles.title1}>
                {renderPricedText(data.text)}
            </p>
              
            <p className={styles.label}>
                {data.subtext}
            </p>

            <Link className={cn("button", styles.contactbutton)} to="/contact">
              Contact Us
            </Link>
        </div>
      </div>
    </>
  )
}

export default BasicPriceCard;
