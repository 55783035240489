import React, { useRef, useEffect } from "react";
import styles from "./ModalDownloader.module.sass";
import classNames from 'classnames';

const DownloadModal = ({ isOpen, onClose }) => {
    const modalRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                onClose();
            }
        };

        if (isOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isOpen, onClose]);

    const handleGooglePlayClick = () => {
        window.location.href = "https://play.google.com/store/apps/details?id=com.spark.mobile.app&hl=en_US";
        onClose();
    };

    const handleAppStoreClick = () => {
        window.location.href = "https://apps.apple.com/us/app/sparkmysport/id1547075908";
        onClose();
    };

    if (!isOpen) return null;

    return (
        <div className={styles.overlay}>
            <div ref={modalRef} className={styles.content}>
                <div className={styles.container}>
                    <div className={styles.title}>Access SparkMySport Network</div>
                    <a className={styles.descriptionlink} href="https://stream.sparkmysport.com/login">Watch Live & On-Demand Games</a>
                    <p className={styles.descriptionprimary}>
                        Discover your favorite local channels and never miss a moment of live or recorded sports action.
                    </p>
                    <div className={styles.titleSpacing}></div> 
                    <div className={styles.title}>Log In to SparkMySport Web App</div>
                    <a className={styles.descriptionlink} href="https://smsweb.sparkmysport.com/login">SparkMySport Web App</a>
                    <p className={styles.description}>
                        Connect with athletes, parents, coaches, and scouts. Easily find players, teams, or league events
                        through our comprehensive listings.
                    </p>
                    <div className={styles.titleSpacing}></div> 
                    <div className={styles.title}>Download the SparkMySport App</div>
                    <p className={styles.description}>
                        Capture game footage, utilize stats boards, and take advantage of powerful annotation tools for in-
                        depth film study and seamless video exchange.
                    </p>
                    <div className={styles.footer}>
                        <div
                            onClick={handleGooglePlayClick}
                            className={styles.appButton}
                        >
                            <img
                                src="/images/playstore.svg"
                                alt="Google Play"
                            />
                        </div>
                        <div
                            onClick={handleAppStoreClick}
                            className={styles.appButton}
                        >
                            <img
                                src="/images/appstore.svg"
                                alt="App Store"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DownloadModal;