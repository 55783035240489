import React from "react";
import styles from "./Contact.module.sass";

const Contact = () => {
  return (
    <div className={styles.page}>
      <div className={styles.title}>
        <p className={styles.blurb}>Talk to us</p>
        <p className={styles.heading}>Get in touch with us</p>
      </div>
      <form
        className={styles.form}
        action="https://formsubmit.co/support@sparkmysport.com"
        method="POST"
        target="_blank"
      >
        <div className={styles.grid}>
          <input type="hidden" name="type" value="contact_forum" />
          <input
            type="text"
            name="first_name"
            className={styles.input_firstname}
            placeholder="First Name"
            required
          />
          <input
            type="text"
            name="last_name"
            className={styles.input_lastname}
            placeholder="Last Name"
            required
          />
          <input
            type="email"
            name="email"
            className={styles.input_email}
            placeholder="Email Address"
            required
          />
          <textarea
            name="message"
            className={styles.input_message}
            placeholder="Your Message"
            rows="10"
            required
          />
        </div>
        <button type="submit" className={styles.emailbtn}>
          <p className={styles.btnlabel}> Send Email</p>
        </button>
      </form>
    </div>
  );
};

export default Contact;
