import React from "react"
import styles from "./PaycardInfo.module.sass"
import cn from "classnames";
import PaycardPriceInfo from "../PaycardPriceInfo";

const PaycardInfo = ({ data }) => {

  return (
    <>
      <div className={cn(styles.paycard, styles.card_active)}>
        <div className={styles.carddiv} />
        <div className={styles.carddesc}>
          {(data).map((item, index) => {
            const key = `${item.type}-${index}`; // Unique key using type and index
            if (item.type === "title") {
              return (
                <p className={styles.title} key={key}>
                  {item.data}
                </p>
              )
            } else if (item.type === "title1") {
              return (
                <p className={styles.title1} key={key}>
                  {item.data}
                </p>
              )
            } else if (item.type === "label") {
              return (
                <p className={styles.label} key={key}>
                  {item.data}
                </p>
              )
            } else if (item.type === "asterik") {
              return (
                <p className={styles.asterik} key={key}>
                  {item.data}
                </p>
              )
            } else if (item.type === "data") {
              return (
                <PaycardPriceInfo key={key} data={item.data} />
              )
            } else {
              return (
                <li className={styles.bullet} key={key}>
                  {item.data}
                </li>
              )
            }
          })}
        </div>
      </div>
    </>
  )
}

export default PaycardInfo;
